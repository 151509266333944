import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import AnimatedComponent from '../components/AnimatedComponent/AnimatedComponent'
import arginine from '../assets/products/arginine.png'
import eyes from '../assets/products/eyes.jpg'
import glutation from '../assets/products/glutation.png'
import indogreen from '../assets/products/indogreen.png'
import iron from '../assets/products/iron.png'
import licorice from '../assets/products/licorice.png'
import magnesium from '../assets/products/magnesium.png'
import nac from '../assets/products/nac.png'
import quercetin from '../assets/products/quercetin.png'
import selen from '../assets/products/selen.png'
import zinc from '../assets/products/zinc.png'
import css from './pages.module.css'
import Article from '../components/Article/Article'

const allArticles = [
  {
    id: 1,
    product_id: null,
    img: eyes,
    title: 'Профілактика та лікування захворювань очей без ліків',
    article: `<p>Щорічно збільшується кількість людей, які страждають від різних захворювань очей. В першу чергу, це пов'язано з постійно зростаючим потоком інформації, яку необхідно переробити за допомогою комп'ютера, або читанням спеціальної літератури. Сприяють погіршенню зору численні алергени, водіння автомобіля в нічний час, тривале перебування на сонці. Досить часто ураження очей обумовлено патологією внутрішніх органів та систем і є одним з проявів порушення обмінних процесів в організмі.</p>
      <br/><p>Дефіцит у раціоні харчування таких мікронутрієнтів як вітаміни, мікроелементи, антиоксиданти, ненасичені жирні кислоти відіграють важливу, якщо не головну роль у патології органів зору.</p>
      <br/><p>Вже в кінці минулого століття було встановлено достовірний зв'язок між забезпеченістю організму мікронутрієнтів антиоксидантної дії і деякими поширеними хронічними дегенеративними захворюваннями органів зору, такими як вікова макулодистрофія сітківки і катаракта, а також ураження сітківки у хворих, які отримують парентеральне (незбалансоване з багатьох мікронутрієнтів) харчування.</p>
      <br/><p>Медикаментозне лікування при помутнінні кришталика (катаракти) передбачає поліпшення обмінних процесів у кришталику, заповнення нестачі різних речовин у кришталику, нормалізація метаболізму і окислювально-відновного балансу. Подібне лікування спрямоване на профілактику прогресування захворювання переважно в початковій стадії. Призначають лікарські засоби, що містять вітаміни В2, С, глутамінову кислоту, таурин, цистеїн і ін На більш пізніх стадіях захворювання проводять хірургічне втручання по заміні кришталика.</p>
      <br/><p>При судинних, дистрофічних і запальних захворюваннях сітківки застосовують судинорозширювальні і поліпшують мікроциркуляцію препарати, спазмолітики, протинабрякові засоби, кортикостероїди.</p>
      <br/><p>Важко піддається медикаментозному лікуванню таке грізне захворювання, як глаукома (патологія внутрішньоочного тиску). Призначають гіпотензивні препарати, діуретики, судинорозширювальні засоби, що поліпшують обмінні процеси в оці. При недостатній ефективності медикаментозного лікування показано лазерні та мікрохірургічні операції. Всі перераховані хронічні захворювання і потребують тривалого медикаментозного лікування, що завжди супроводжується побічними і небажаними ефектами для організму, тому застосування природних мікронутрієнтів та фітопрепаратів для лікування і профілактики даних захворювань досить актуально.</p>
      <br/><p>Компанія Виталайн пропонує три препарати, що містять усі необхідні мікронутрієнти, що забезпечують антиоксидантний захист органів зору, а також беруть участь у процесах кольорового сприйняття і регулюють кровопостачання сітківки ока. Це <a href="product/138">Чорниця Віта Комплекс</a>, <a href="product/96">Віта-Віжіон</a> і <a href="product/156">Гінкго Білоба</a>.</p>
      <br/><p>Основним компонентом препарату <a href="product/138">Чорниця Віта Комплекс</a> є екстракт плодів чорниці, який містить фармакологічно активні антоцианідіни, зміцнюють стінки капілярів судинної оболонки, сітківки та попереджувальні дегенерацію жовтої плями сітківки. Чорниця підвищує гостроту і збільшує поле зору, зменшує втому очей від тривалої роботи при штучному освітленні.</p>
      <br/><p>Приймають <a href="product/138">Чорниця Віта Комплекс</a> по 1 капсулі 3-4 рази в день протягом 1-2 місяців, після 2-3 тижневої перерви прийом препарату можна повторити.</p>
      <br/><p>Комплексний препарат <a href="product/96">Віта-Віжіон</a> містить всі необхідні мікронутрієнти, що покращують роботу органів зору. Так, вітамін А або ретинол безпосередньо вказує на значення даного мікронутрієнта для зорової функції ока (retina — сітківка). При його дефіциті в організмі виснажується зоровий пігмент родопсин і розвивається так звана «куряча сліпота». Ретинол регулює процеси регенерації епітеліальних тканин, включаючи епітелій протоків слізних залоз.</p>
      <br/><p>У препараті містяться каротиноїди, необхідні для правильного функціонування органів зору. Лютеїн і зеаксантин або макулярные ксантофіли виконують функцію природних жовтих світлофільтрів, що запобігають пошкодження зорових клітин від синьої частини світлового спектра. Крім того, ці каротиноїди є ключовими антиоксидантами, що захищають сітківку від окисних процесів, викликаних світловим опроміненням. Ну, а бета-каротин є попередником вітаміну А.</p>
      <br/><p>Флавоноїди, що містяться в перерахованих вище препаратах, є активними антиоксидантами, пригнічують фотоокислительные процеси катарактогенеза (запобігають розвиток катаракти).</p>
      <br/><p>Приймають <a href="product/96">Віта-Віжіон</a> по 1 капсулі 3 рази на день тривало.</p>
      <br/><p>Екстракти гінкго давно й успішно застосовуються для покращання мікроциркуляції. У зв'язку з цим препарат <a href="product/156">Гінкго Білоба</a> перспективний при всіх порушеннях роботи органів зору, в тому числі для профілактики і в комплексних програмах лікування глаукоми. Це пов'язано з тим, що гінкго білоба нормалізує внутрішньоочної кровообіг і циркуляцію внутрішньоочної рідини, чинить антиагрегантну і нейропротекторну дію щодо зорового нерва. Антиоксидантну дію екстракту гінкго також яскраво виражено.</p>
      <br/><p>Приймають <a href="product/156">Гінкго Білоба</a> по 2 капсули 2 рази на день у першій половині дня протягом 1-2 місяців, роблять перерву на 2-3 тижні, потім прийом препарату можна повторити.</p>
      <br/><p>Примітка. Додатково до основної схеми рекомендуємо приймати <a href="product/110">Мега Плюс</a> по 1 капсулі 2-3 рази на день, оскільки омега-3-поліненасичені жирні кислоти відіграють дуже важливу роль для функціонування зорових клітин і для процесу кольорового сприйняття, зокрема. Препарат бажано приймати разом з <a href="product/149">Супер Е</a> (по 1 капсулі в день) і <a href="product/131">Супер C</a> (по 2-3 таблетки на день одноразово) для попередження окислення поліненасичених жирних кислот.</p>`,
  },
  {
    id: 2,
    product_id: 120,
    img: iron,
    title: 'Трохи про наше "Залізо Есенціальне"',
    article: `<p>Препарат «Залізо Есенціальне» містить, крім глюконату заліза, кальцію аскорбат і фолієву кислоту. Глюконат заліза надає протианемічну дію, заповнюючи дефіцит заліза у організмі, стимулює синтез гемоглобіну при залізодефіцитних анеміях. Препарат характеризується високою біодоступністю, оскільки двовалентне залізо (закісне) легко абсорбується, а глюкуронова кислота підсилює його всмоктування.</p>
    <br/><p>Додатковий прийом заліза рекомендується при підвищеній потребі організму у залізі: вагітність, лактація, інтенсивне зростання, особливо у дівчаток, при недостатньому надходженні або порушенні всмоктування заліза (хронічна діарея, глистні інвазії), а також в осіб похилого та старечого віку. Оскільки залізо – окисний агент (це означає, що воно може бути причиною виникнення вільних радикалів, які можуть ушкодити тканини), то його бажано приймати разом з антиоксидантами. З цією метою до складу препарату входить вітамін С у вигляді аскорбату кальцію у дуже маленькій кількості, у зв'язку з тим, що зайвий кальцій може конкурувати із залізом у кишечнику за всмоктування. Фолієва кислота у продукті (у невеликій кількості) є коферментом - «помічником» заліза у хімічних реакціях біосинтезу білка, що необхідно для нормального продукування червоних кров'яних клітин і клітинного поділу. Прийом препарату під час їжі від 1 до 3 разів на день за вказівкою лікаря.</p>`,
  },
  {
    id: 3,
    product_id: 152,
    img: licorice,
    title: 'Солодка Плюс (Лакричник) / Licorice',
    article: `<p>Препарати на основі коренів солодки стандартизують по змісту гліцирізинової кислоти. Чим більше гліцирізинової кислоти, тим краще. У корінні солодки не менше 6% цієї речовини, в екстрактах від 14 до 24%. У нашому препараті зміст гліцирізинової кислоти не більше 1,5%. Вся справа у тому, що гліцирізинова кислота діє подібно гідрокортизону та іншим кортикостероїдним препаратам і має ряд схожих побічних ефектів (затримка натрію і води в організмі, підвищення артеріального тиску та інше). Наш препарат максимально позбавлений цих побічних ефектів. Звертаємо Вашу увагу, гліцирізинова кислота має нудотно солодкий смак, тому чим менш солодкі таблетки солодки, тим менше в них гліцирізинової кислоти, відповідно, і менше побічних ефектів. Сапоніни солодки, в тому числі гліцирізинова кислота, мають виражену відхаркувальну дію. Оскільки в нашому препараті гліцирізинової кислоти мало, то відхаркувальна дія буде не яскраво виражена.</p>
    <br/><p>Крім сапонінів, в солодці міститься велика кількість флавоноїдів різних груп, які потенціюють основну дію, а також мають протизапальну, спазмолітичну, протигістамінну (протиалергічну), противиразкову дію, сприяють зниженню секреції шлункового соку, нормалізують проникність судинної стінки. Ізофлавоноїди солодки виявляють естрогенну активність, тому Солодка Плюс може бути рекомендована жінкам для зменшення вегетативних проявів у період клімаксу, ефективна при запальних захворюваннях жіночих статевих органів. Солодка Плюс буде корисна при захворюваннях органів дихання, при алергічних проявах (наприклад, алергічні дерматити), при запальних процесах будь-якої локалізації, виразкової хвороби шлунка і 12-палої кишки, особливо на фоні підвищеної секреції шлункового соку, як загальнозміцнюючий засіб. Солодку застосовують в якості протиотрути і засобу, коригуючого смакові відчуття.</p>`,
  },
]

const ArticlesPage = () => {
  const [articles, setArticles] = useState([])
  const [active, setActive] = useState([])

  const navigate = useNavigate()
  const pageTopRef = useRef(null)

  useEffect(() => {
    setArticles(allArticles)
    document.title = 'Vitaline'
    pageTopRef.current.scrollIntoView()
  }, [])

  const handleToggle = (id, e) => {
    if (active.find((el) => +el === +id))
      return setActive(active.filter((el) => +el !== +id))

    setActive([...active, id])
  }

  const goToProduct = (id, e) =>
    setTimeout(() => {
      navigate(`/product/${id}`)
    }, 300)

  return (
    <AnimatedComponent>
      <main className={css.main} ref={pageTopRef}>
        {articles.length > 0 && (
          <div className={css.articlesWrapper}>
            <ul>
              {articles.map((el) => (
                <Article
                  key={el.id}
                  el={el}
                  active={active}
                  handleToggle={handleToggle}
                  goToProduct={goToProduct}
                />
              ))}
            </ul>
          </div>
        )}
      </main>
    </AnimatedComponent>
  )
}

export default ArticlesPage
